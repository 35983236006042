body { background-color: var(--clr-27070); }
body { color: var(--clr-27071); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: var(--clr-27073);
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.1;
text-transform: uppercase;
letter-spacing: 0;
font-size: 25px;

@media #{$medium-up} {
font-size: 35px;

}
@media #{$large-up} {
font-size: 40px;

}
}
h2 {
color: var(--clr-27073);
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.1;
text-transform: uppercase;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 28px;

}
@media #{$large-up} {
font-size: 35px;

}
}
h3 {
color: var(--clr-27073);
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.1;
text-transform: uppercase;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 24px;

}
@media #{$large-up} {
font-size: 28px;

}
}
h4 {
color: var(--clr-27072);
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.1;
text-transform: uppercase;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 22px;

}
@media #{$large-up} {
font-size: 25px;

}
}
h5 {
color: var(--clr-27072);
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.1;
text-transform: uppercase;
letter-spacing: 0;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: var(--clr-27072);
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.1;
text-transform: uppercase;
letter-spacing: 0;
font-size: 16px;

}
.button {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 16px;

}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Arapey';
font-weight: normal;
font-style: italic;
line-height: 1.5;
font-size: 24px;

@media #{$medium-up} {
font-size: 28px;

}
@media #{$large-up} {
font-size: 38px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: var(--clr-27072);
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: 25;
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 25px;

@media #{$medium-up} {
font-size: 30px;

}
@media #{$large-up} {
font-size: 38px;

}
}
summary {
font-family: 'Montserrat';
font-weight: 600;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 22px;

}
@media #{$large-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

}
nav.mainmenu > .menu-item > div > a {
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

}
nav.secondarymenu > .menu-item > a {
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

}
nav.me-RespMenu.responsive-menu a {
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
p { padding-bottom: 1em; }
a {color: var(--clr-27072);}
/* Primary:3 */
.MES3 {
background-color: var(--clr-27073);
color: #ffffff;
 }
/* Primary:4 */
.MES4 {
background-color: var(--clr-27073);
color: #ffffff;
 }
.MES4 {
background-color: var(--clr-27073);
color: #ffffff;
h1.MEC4, h2.MEC4, h3.MEC4, h4.MEC4, h5.MEC4, h6.MEC4 { color: #ffffff;
 }
 }
cite.MEC4{
color: #ffffff;
}
/* Secondary:5 */
.MES5 {
background-color: var(--clr-27073);
color: #ffffff;
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
padding: 10px 15px;

 }
/* Secondary:6 */
.MES6 {
background-color: var(--clr-27073);
color: #ffffff;
 }
.MES6 {
background-color: var(--clr-27073);
color: #ffffff;
h1.MEC6, h2.MEC6, h3.MEC6, h4.MEC6, h5.MEC6, h6.MEC6 { color: #ffffff;
 }
 }
cite.MEC6{
color: #ffffff;
}
/* Dark:7 */
.MES7 {
background-color: var(--clr-27071);
color: #ffffff;
 }
/* Dark:8 */
.MES8 {
background-color: var(--clr-27071);
color: var(--clr-27070);
 }
.MES8 {
background-color: var(--clr-27071);
color: var(--clr-27070);
h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: var(--clr-27070);
 }
 }
a.MEC8 { color: var(--clr-27070);
&:hover { color: var(--clr-27073);}
 }
cite.MEC8{
color: var(--clr-27070);
}
/* Light:9 */
.MES9 {
background-color: var(--clr-27070);
 }
/* Light:10 */
.MES10 {
background-color: var(--clr-27070);
 }
.MES10 {
background-color: var(--clr-27070);
 }
/* Shade 1:11 */
.MES11 {
background-color: var(--clr-27074);
color: var(--clr-27071);
&:hover { color: var(--clr-27071);}
 }
/* Shade 1:12 */
.MES12 {
background-color: var(--clr-27074);
 }
.MES12 {
background-color: var(--clr-27074);
 }
/* Menu:13 */
.MES13 {
background-color: #ffffff;
color: var(--clr-27071);
font-size: 16px;
@media #{$medium-up} {
font-size: 17.6px;
};
@media #{$large-up} {
font-size: 19.2px;
};
min-height:50px;
@media #{$medium-up} {
min-height: 80px;};
@media #{$large-up} {
min-height: 50px;};
padding: 0;

border-width: 0 0 3px 0;
border-style: solid;
border-color: transparent transparent var(--clr-27070) transparent;
 }
.MES13 {
background-color: #ffffff;
color: var(--clr-27071);
font-size: 16px;
@media #{$medium-up} {
font-size: 17.6px;
};
@media #{$large-up} {
font-size: 19.2px;
};
min-height:50px;
@media #{$medium-up} {
min-height: 80px;};
@media #{$large-up} {
min-height: 50px;};
padding: 0;

border-width: 0 0 3px 0;
border-style: solid;
border-color: transparent transparent var(--clr-27070) transparent;
 }
a.MEC13 { color: var(--clr-27071);
&:hover { color: var(--clr-27071);}
 }
cite.MEC13{
color: var(--clr-27071);
font-size: 16px;
@media #{$medium-up} {
font-size: 17.6px;
};
@media #{$large-up} {
font-size: 19.2px;
};
}
/* home block panels:14 */
.MES14 {
background-color: #000000;
color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 500px;};
 }
.MES14 {
background-color: #000000;
color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 500px;};
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: #ffffff;
 }
 }
.MEC14 li {color: #ffffff;}
cite.MEC14{
color: #ffffff;
}
/* Pop out text:15 */
.MES15 {
background-color: #ffffff;
color: var(--clr-27071);
font-size: 16px;
 }
.MES15 {
background-color: #ffffff;
color: var(--clr-27071);
font-size: 16px;
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: var(--clr-27071);
 }
 }
.MEC15 li {color: var(--clr-27071-flat);}
cite.MEC15{
color: var(--clr-27071);
font-size: 16px;
}
/* Hollaw:16 */
.MES16 {
background-color: transparent;
&:hover {background-color: var(--clr-27075);}
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 16px;
@media #{$large-up} {
font-size: 17.6px;
};
border-width: 2px;
border-style: solid;
border-color: #ffffff;
&:hover { border-color: #ffffff; }
 }
/* :17 */
.MES17 {
 }
/* Contents:18 */
.MES18 {
background-color: #ffffff;
&:hover, &.hover { background-color: #ffffff;}
color: var(--clr-27071);
font-size: 16px;
 }
.MES18 {
background-color: #ffffff;
&:hover, &.hover { background-color: #ffffff;}
color: var(--clr-27071);
font-size: 16px;
 }
a.MEC18 { color: var(--clr-27073);
&:hover { color: var(--clr-27071);}
 }
cite.MEC18{
color: var(--clr-27071);
font-size: 16px;
}
/* Slider text white 2:19 */
.MES19 {
 }
.MES19 {
h1.MEC19, h2.MEC19, h3.MEC19, h4.MEC19, h5.MEC19, h6.MEC19 { color: #ffffff;
 }
h1.MEC19 { @media #{$large-up} { font-size: 48px; }; }
h2.MEC19 { @media #{$large-up} { font-size: 42px; }; }
h3.MEC19 { @media #{$large-up} { font-size: 33.6px; }; }
h4.MEC19 { @media #{$large-up} { font-size: 30px; }; }
h5.MEC19 { @media #{$large-up} { font-size: 24px; }; }
h6.MEC19 { @media #{$large-up} { font-size: 19.2px; }; }
 }
/* Footer Dark:20 */
.MES20 {
background-color: var(--clr-27073);
color: var(--clr-27070);
font-size: 12.8px;
 }
.MES20 {
background-color: var(--clr-27073);
color: var(--clr-27070);
font-size: 12.8px;
h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: var(--clr-27070);
 }
 }
a.MEC20 { color: #ffffff;
&:hover { color: var(--clr-27074);}
 }
cite.MEC20{
color: var(--clr-27070);
font-size: 12.8px;
}
/* product border:21 */
.MES21 {
color: var(--clr-27071);
border-width: 1px;
border-style: solid;
border-color: var(--clr-27076);
 }
.MES21 {
color: var(--clr-27071);
border-width: 1px;
border-style: solid;
border-color: var(--clr-27076);
h1.MEC21, h2.MEC21, h3.MEC21, h4.MEC21, h5.MEC21, h6.MEC21 { color: var(--clr-27073);
 }
 }
cite.MEC21{
color: var(--clr-27071);
}
/* About FAQ:22 */
details.MES22 {
& > summary{background-color: var(--clr-27070);
}
& > summary{padding: 10px 0;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent #ffffff transparent;
} }
/* Home FAQ:23 */
details.MES23 {
& > summary{background-color: var(--clr-27070);
}
color: var(--clr-27073);
font-size: 14.4px;
& > article { color: var(--clr-27073);
font-size: 14.4px;
 }
& > summary{padding: 10px 0;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent #ffffff transparent;
} }
/* Product Tile:24 */
.MES24 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-27070);}
color: var(--clr-27073);
font-size: 16px;
border-width: 1px;
border-style: solid;
border-color: var(--clr-27070);
 }
.MES24 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-27070);}
color: var(--clr-27073);
font-size: 16px;
border-width: 1px;
border-style: solid;
border-color: var(--clr-27070);
h1.MEC24, h2.MEC24, h3.MEC24, h4.MEC24, h5.MEC24, h6.MEC24 { color: var(--clr-27073);
 }
h1.MEC24 { @media #{$large-up} { font-size: 32px; }; }
h2.MEC24 { @media #{$large-up} { font-size: 28px; }; }
h3.MEC24 { @media #{$large-up} { font-size: 22.4px; }; }
h4.MEC24 { @media #{$large-up} { font-size: 20px; }; }
h5.MEC24 { @media #{$large-up} { font-size: 16px; }; }
h6.MEC24 { @media #{$large-up} { font-size: 12.8px; }; }
 }
cite.MEC24{
color: var(--clr-27073);
font-size: 16px;
}
/* Dark:26 */
.MES26 {
background-color: var(--clr-27071);
color: #ffffff;
padding: 15px 40px;

 }
/* Check out:27 */
.MES27 {
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
padding: 5px;

border-width: 1px;
border-style: solid;
border-color: #ffffff;
&:hover { border-color: #ffffff; }
 }
/* Top Bar:28 */
.MES28 {
background-color: var(--clr-27073);
color: #ffffff;
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
 }
.MES28 {
background-color: var(--clr-27073);
color: #ffffff;
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
h1.MEC28, h2.MEC28, h3.MEC28, h4.MEC28, h5.MEC28, h6.MEC28 { color: #ffffff;
 }
 }
a.MEC28 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC28{
color: #ffffff;
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
}
/* home block panels:29 */
.MES29 {
background-color: var(--clr-27077);
color: var(--clr-27071);
 }
.MES29 {
background-color: var(--clr-27077);
color: var(--clr-27071);
h1.MEC29, h2.MEC29, h3.MEC29, h4.MEC29, h5.MEC29, h6.MEC29 { color: var(--clr-27071);
 }
 }
a.MEC29 { color: var(--clr-27071);
&:hover { color: var(--clr-27078);}
 }
.MEC29 li {color: var(--clr-27071-flat);}
cite.MEC29{
color: var(--clr-27071);
}
/* Category Panel:30 */
.MES30 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-27074);}
color: var(--clr-27073);
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: var(--clr-27070);
 }
.MES30 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-27074);}
color: var(--clr-27073);
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: var(--clr-27070);
h1.MEC30, h2.MEC30, h3.MEC30, h4.MEC30, h5.MEC30, h6.MEC30 { color: var(--clr-27073);
 }
 }
cite.MEC30{
color: var(--clr-27073);
}
/* Slider text black 2:31 */
.MES31 {
 }
.MES31 {
h1.MEC31, h2.MEC31, h3.MEC31, h4.MEC31, h5.MEC31, h6.MEC31 { color: var(--clr-27073);
 }
h1.MEC31 { @media #{$large-up} { font-size: 48px; }; }
h2.MEC31 { @media #{$large-up} { font-size: 42px; }; }
h3.MEC31 { @media #{$large-up} { font-size: 33.6px; }; }
h4.MEC31 { @media #{$large-up} { font-size: 30px; }; }
h5.MEC31 { @media #{$large-up} { font-size: 24px; }; }
h6.MEC31 { @media #{$large-up} { font-size: 19.2px; }; }
 }
/* Phone Block:32 */
.MES32 {
min-height:50px;
@media #{$medium-up} {
min-height: 50px;};
@media #{$large-up} {
min-height: 50px;};
 }
.MES32 {
min-height:50px;
@media #{$medium-up} {
min-height: 50px;};
@media #{$large-up} {
min-height: 50px;};
 }
/* Back menu:33 */
nav.me-Menu.MES33 {
.menu-item.MEC33{background-color: var(--clr-27073); &:hover {background-color: var(--clr-27073);}
}
& .menu-item.MEC33, & .menu-item.MEC33 > div.MEC33{ & > a.MEC33{color: #ffffff;
font-size: 16px;
text-transform: uppercase;
}
 &:hover > a.MEC33{color: #ffffff;
}
 }
&.horizontal > .menu-item.MEC33 { border:0;
border-color: var(--clr-27070);
border-style: solid;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC33 { border:0;
border-color: var(--clr-27070);
border-style: solid;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC33 .menu-item { border:0;
border-bottom-width: 1px;
border-color: 6;
border-style: dotted;
 }
&.horizontal > .menu-item.MEC33 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 10px 30px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Custom login Panel:34 */
.MES34 {
background-color: var(--clr-27070);
&:hover, &.hover { background-color: var(--clr-27074);}
color: var(--clr-27073);
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px;

@media #{$medium-up} {
padding: 15px 20px;

}
@media #{$large-up} {
padding: 15px 30px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-27070);
 }
.MES34 {
background-color: var(--clr-27070);
&:hover, &.hover { background-color: var(--clr-27074);}
color: var(--clr-27073);
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px;

@media #{$medium-up} {
padding: 15px 20px;

}
@media #{$large-up} {
padding: 15px 30px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-27070);
h1.MEC34, h2.MEC34, h3.MEC34, h4.MEC34, h5.MEC34, h6.MEC34 { color: var(--clr-27073);
 }
 }
cite.MEC34{
color: var(--clr-27073);
}
/* Separator:35 */
.MES35 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-27071);}
& > hr {&:hover { border-color: var(--clr-27071);} }
& > hr {border-top-style: dotted;}
 }
/* Slider panel box:36 */
.MES36 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-27075);}
padding: 15px;

@media #{$medium-up} {
padding: 30px;

}
border-width: 4px;
border-style: solid;
border-color: #ffffff;
 }
.MES36 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-27075);}
padding: 15px;

@media #{$medium-up} {
padding: 30px;

}
border-width: 4px;
border-style: solid;
border-color: #ffffff;
h1.MEC36, h2.MEC36, h3.MEC36, h4.MEC36, h5.MEC36, h6.MEC36 { color: #ffffff;
 }
h1.MEC36 { @media #{$large-up} { font-size: 48px; }; }
h2.MEC36 { @media #{$large-up} { font-size: 42px; }; }
h3.MEC36 { @media #{$large-up} { font-size: 33.6px; }; }
h4.MEC36 { @media #{$large-up} { font-size: 30px; }; }
h5.MEC36 { @media #{$large-up} { font-size: 24px; }; }
h6.MEC36 { @media #{$large-up} { font-size: 19.2px; }; }
 }
a.MEC36 { color: #ffffff;
 }
/* Banner panel:37 */
.MES37 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://advanceimports.com.au/img/6850/4487');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
background-attachment: fixed;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 500px;};
 }
.MES37 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://advanceimports.com.au/img/6850/4487');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
background-attachment: fixed;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 500px;};
h1.MEC37, h2.MEC37, h3.MEC37, h4.MEC37, h5.MEC37, h6.MEC37 { color: #ffffff;
 }
 }
.MEC37 li {color: #ffffff;}
cite.MEC37{
color: #ffffff;
}
/* grey separator:39 */
.MES39 {
& > hr {border-width: 1px;}
& > hr {border-color: var(--clr-27074);}
& > hr {border-top-style: dotted;}
& > hr {border-right-style: dotted;}
& > hr {border-bottom-style: dotted;}
& > hr {border-left-style: dotted;}
 }
/* Cart Button:40 */
.MES40 {
background-color: var(--clr-27073);
color: #ffffff;
padding: 6.5px;

 }
/* Small text block:41 */
.MES41 {
font-size: 12.8px;
 }
.MES41 {
font-size: 12.8px;
 }
cite.MEC41{
font-size: 12.8px;
}
/* Main Menu:42 */
nav.me-Menu.MES42 {
& .menu-item.MEC42, & .menu-item.MEC42 > div.MEC42{ & > a.MEC42{color: var(--clr-27071);
text-transform: uppercase;
}
  }
&.horizontal > .menu-item.MEC42 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC42 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC42 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC42 .sub-menu { border:1px;
 }
& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* :43 */
nav.responsive-menu {
& .menu-item.MEC43, & .menu-item.MEC43 > div.menu-item-wrap{ & > a.MEC43, & > i{color: var(--clr-27073);
text-align: center;
}
  }
& .menu-item.MEC43 { border:0;

 border-bottom-width: 1px; }
& .sub-menu{ .sub-menu {}}

 }
