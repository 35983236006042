.mebuild-popup-modal {
  opacity: 0;
}

.mebuild-popup-mask.mebuild-popup-active {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.8);
}
.mebuild-popup-loader {
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  color: white;
  z-index: 9999;
}

.mebuild-popup-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 9999;
  background: white;
  border-radius: 10px;
  border: 10px solid rgba(0, 0, 0, 0.6);
  padding: 5px;
  .modal-wrap {
    overflow-y: auto;
    @media #{$small-only} {
      -webkit-overflow-scrolling: touch;
    }
    @media #{$medium-up} {
      overflow-y: auto;
    }
    overflow-x: hidden;
    width: 100%;
    height: 100%;
  }
  i.mebuild-popup-close {
    position: absolute;
    bottom: 5px;
    right: 5px;
    border: 1px solid;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 2px 0;
    cursor: pointer;
    z-index: 9;
    @media #{$small-only} {
      font-size: 23px;
    }
  }
}

.bldpopup-full-screen {
  &.mebuild-popup-mask {
    background-color: transparent !important;
  }
  /*&.mebuild-popup-current {*/
  border: 0 !important;
  padding: 0 !important;
  height: 100vh !important;
  width: 100vw !important;
  max-height: 100vh !important;
  max-width: 100vw !important;
  transform: none !important;
  left: 0;
  top: 0;
  background-color: transparent !important;
  & > .modal-wrap {
    max-height: 100vh !important;
  }
  .mebuild-popup-close {
    display: none;
  }
  /*}*/
}
