i.mebuild-search-solr-icon {
  font-size: 200%;
  &.bldpage:before { content: "\f0f6"; }
  &.page:before { content: "\f0f6"; }
  &.news:before { content: "\f0a1"; }
  &.blog:before { content: "\f18d"; }
  &.prodsimple:before { content: "\f1b2"; }
  &.file:before { content: "\f019"; }
  &.file.pdf:before { content: "\f1c1"; }
  &.file.csv:before { content: "\f1c3"; }
  &.file.xlsx:before { content: "\f1c3"; }
  &.file.docx:before { content: "\f1c2"; }
}

.me-SolrSearchInput {
  form {
    display:flex;
  }
  .solr-search-input {
    flex-grow: 1;
    flex-direction: column;
    display: flex;
  }
  input { flex-grow:1; }
  input, button { margin: 0;}
}