//$MEDefaultBgImage: url("/s~#{$METhemeName}/0/page-bg.png") !default;
//$MEDefaultMaxWidth: 1280px !default; //Maximum Row Width
//// Panel Settings
//// ==============
//$MEPanelDefaultFg: white;
//$MEPanelDefaultLink: white;
//$MEPanelDefaultBg: $MEDefaultBg;
//$MEPanelNormalBg: #f2f2f2 !default;
//$MEPanelNormalFg: $MEDefaultFg !default;
//$MEPanelInvertedFg: $MEInvertedFg !default;
//$MEPanelInvertedBg: $MEInvertedBg !default;
//$MEPanelPrimaryFg: $MEPrimaryFg !default;
//$MEPanelPrimaryBg: $MEPrimaryBg !default;
//$MEPanelSecondaryFg: $MESecondaryFg !default;
//$MEPanelSecondaryBg: $MESecondaryBg !default;
//$MEPanelBorderNormal: 1px solid #d8d8d8 !default;
//$MEPanelBorderInverted: 1px solid #333 !default;
//$MEPanelBorderPrimary: 1px solid #003 !default;
//$MEPanelBorderSecondary: gold !default;
//$MEPanelPad: $MEDefaultBlockPad !default;
//
//// Columns Settings
//// ================
//$MEColumnBg: $MEDefaultBg !default;
//$MERowBg: $MEDefaultBg !default;
//$MERowPad: 20px !default;
//
//// Heading Settings
//// ================
//$MEHeadAlign: left !default;               // Default Heading Alignment
//$MEHeadFontFamily: $MEDefaultFontFamily !default; //Heading Font
//$MEHeadFg: $MEDefaultFg !default;          // Heading Colour
//$MEHeadInvFg: $MEDefaultBg !default;       // Heading Inverted Colour
//$MEHeadPrimaryFg: $MEPrimaryFg !default;   // Heading Primary Colour
//$MEHeadSecondaryFg: $MEPrimaryFg !default; // Heading Secondary Colour
//
// MB2 Block = Menu
// ****************
//$MEMenuFg                           : $MEInvertedLink !default;
//$MEMenuFgHover                      : $MEInvertedLinkHover !default;
//$MEMenuBg                           : $MEInvertedBg !default;
//$MEMenuBgHover                      : $MEInvertedBgHover !default;
//$MEMenuFontFamily                   : $MEDefaultFontFamily !default;
//$MEMenuFontSize                     : 16px !default;
//$MEMenuSepBorder                    : 1px dotted #FFFFFF !default;
//$MEMenuItemMargin                   : 5px !default;
//$MEMenuItemPad                      : 5px !default;
//$MEMenuPadding                      : 5px 10px !default;
//$MEMenuSubTop                       : 10px !default;
//$MEMenuSubItemBg                    : rgba(0,0,0,0.7) !default;
//$MEMenuSubItemPad                   : 10px !default;
//$MEMenuSubItemHoverBg               : rgba(120,120,120, 0.8) !default;
//$MEMenuSubLv3Sep                    : $MEDefaultBg !default;
//$MEMenuSubLv3ItemBg                 : $MEMenuSubItemBg !default;
//$MEMenuSubSepBorder                 : 1px dotted #FFFFFF !default;
//
//
//// Button Settings
//// ===============
//$MEButtonDefaultFg: $MEInvertedFg !default;
//$MEButtonDefaultBg: $MEInvertedBg !default;
//$MEButtonPrimaryFg: $MEPrimaryFg !default;
//$MEButtonPrimaryBg: $MEPrimaryBg !default;
//$MEButtonSecondaryFg: $MESecondaryFg !default;
//$MEButtonSecondaryBg: $MESecondaryBg !default;
//$MEButtonDefaultWidth: 300px !default;
//
//// Accordion Settings
//// ==================
//$MEAccordionTitleBg:      linear-gradient(to bottom, #cccccc 0%, #e8e8e8 100%) !default;
//$MEAccordionTitlePad:     5px 10px !default;
//$MEAccordionTitleBorder:  1px solid #999 !default;
//$MEAccordionTitleBorderBottom: true !default;
//$MEAccordionTitleRadius:  5px !default;
//$MEAccordionTitleFontSize: $MEDefaultFontSize !default;
//$MEAccordionTitleColor:  $MEDefaultFg !default;
//$MEAccordionIconSize:     $MEDefaultFontSize !default;
//$MEAccordionIconColor:    $MEDefaultLink !default;
//$MEAccordionBodyBorder:   1px solid #999 !default;
//$MEAccordionBodyPad:      0 $MEDefaultBlockPad !default;
//$MEAccordionIconMargin:   5px !default;
//$MEAccordionBodyBgColor   : transparent !default;
//
// Tab Settings
// ============

$METabPadding: 10px 0 !default;
$METabLargePadding: 10px 0 !default;

$METabBorderWidth: 0 !default;
$METabBorderColor: rgba(0, 0, 0, 0) !default;
$METabRadius: 0 !default;

$METabBottomTabSpace: 0 !default;
$METabBottomTabPosition: 0 !default;
$METabArrow: true !default;
// Content Style
$METabContentPadding: 10px !default;
$METabMediumContentPadding: 20px !default;
$METabLargeContentPadding: 30px !default;
$METabContentBorderWidth: 1px !default;
$METabContentBorderColor: #e8e8e8 !default;
// tab down arrow
$METabArrow: true !default;
$METabArrowWidth: 10px !default;
// tab separator
$METabSepWidth: 1px !default;
$METabSepStyle: solid !default;
$METabSepColor: #ffffff !default;
//
//
//// FAQ Settings
//// ============
//$MEFaqHeadBg                : transparent !default;
//$MEFaqHeadPadding           : 30px 10px !default;
//$MEFaqHeadBorderBottom      : true !default;
//$MEFaqHeadBorder            : 1px dotted #555555 !default;
//$MEFaqHeadFontSize          : 18px !default;
//$MEFaqHeadFontColor         : #555555 !default;
//$MEFaqHeadRadius            : 0 !default;
//$MEFaqIconDirection         : left !default;
//$MEFaqIconFontSize          : 18px !default;
//$MEFaqIconFontColor         : #555555 !default;
//$MEFaqBodyPadding           : 10px !default;
//$MEFaqBodyBorder            : #555555 !default;
//$MEFaqBodyFontColor         : inherit !default;
//$MEFaqPad                   : 0 !default;
//$MEFaqBodyFontSize          : 14px !default;
//$MEFaqIconSpace             : 5px !default;
//$MEFaqBodyBgColor           : transparent !default;
//$MEFaqHeadLineHeight        : 1 !default;
//// Icon Settings
//// =============
//$MEIconPrimary: $MEPrimaryBg !default;
//$MEIconSecondary: $MESecondaryBg !default;
//
//
//// Form Settings
//// =============
//$MEFormSpacing: 0 0 1rem 0 !default;
//$MEFormSubmitBg: $MESecondaryBg !default;
//
//
//// Masonry Settings
//// ================
//$MEMasonryColumns                 : 3 !default;
//$MEMasonryOutterPad               : 1.5em 20px !default;
//$MEMasonryColumnPad               : 1.5em !default;
//$MEMasonryItemPad                 : 1em !default;
//$MEMasonryItmeBottomSpace         : 1.5em !default;
//
//
////Modules
//
////MODULE BLOCK - TAB
//$tabBgType                          : color !default; // image OR color
//
//$tabBgImage                         : linear-gradient(to bottom, #cccccc, #eeeeee) !default;
//$activeBgImage                      : linear-gradient(to bottom, #eeeeee, #cccccc) !default;
//
//$tabBgColor                         : #333 !default;
//$activeBgColor                      : #333 !default;
//
//
//$tabPadding                         : 5px 15px !default;
//$tabSpacing                         : 1px !default;
//
//$tabBorderWidth                     : 1px !default;
//$tabBorderColor                     : #aaaaaa !default;
//$tabRadius                          : 5px !default;
//
//$tabFontFamily                      : inherit !default;
//$tabFontSize                        : inherit !default;
//$tabFontColor                       : inherit !default;
//$activeFontColor                    : inherit !default;
//
//$bottomTabSpace                     : 60px !default;
//$bottomTabPosition                  : 11px !default;
//
//$contentPadding                     : 10px !default;
//$contentBorderWidth                 : 1px !default;
//$contentBorderColor                 : #aaaaaa !default;
//$contentBgColor                     : #FFFFFF !default;
//
//$tabArrow                           : true !default;
//$arrowWidth                         : 7px !default;
//
//$sepWidth                           : 1px !default;
//$sepStyle                           : solid !default;
//$sepColor                           : #FFFFFF !default;
//
//
////MODULE BLOCK - NEWS
//$newsHeadingFontSize                : 18px !default;
//$newsSummaryFontSize                : 12px !default;
//$newsSummaryLineHeight              : 16px !default;
//$newsSummaryAlignment               : justify !default;
//
//
//// MODULE BLOCK - SLIDER-TRIANGLE_ARROWS
//$outterArrowSize                    : 50px !default;
//$outterArrorColor                   : rgba(123, 191, 242, 0.8) !default;
//$innerArrowSize                     : 20px !default;
//$innerArrowColor                    : #FFFFFF !default;
//
//// MODULE BLOCK - FILE BLOCK
//$MEFilePad                          : 8px 0 !default;
//$MEFileBg                           : $MEPanelPrimaryBg !default;
//$MEFileHoverBg                      : $MEPanelSecondaryBg !default;
//$MEFileColor                        : $MEPanelPrimaryFg !default;
//$MEFileHoverColor                   : $MEPanelPrimaryFg !default;
//$MEFileRadius                       : 3px !default;
//
////Admin Area
//$MEBuildBlockHLColor: #ff8c00 !default;
//
//
//// Testimonials Settings
//// =====================
//$METestimFontFamily                 : Arial !default;
//$MEQuoteFontSize                    : 40px !default;
//$METestimPad                        : 0 70px !default;
//$METestimAuthorAlign                : right !default;
//
//
//// Filter Settings
//// ================
//$MEFilterImgPad                       : 5px !default;
//$MEFilterImgBg                        : #e8e8e8 !default;
//$MEFilterTitlePad                     : 10px 0 !default;
//$MEFilterMenuBorder                   : 1px dotted black !default;
//$MEFilterMenuPad                      : 10px 0 !default;
//
//// News Settings
//// =============
//$MENewsButtonMargine                : 10px auto !default;
//$MENewsButtonBg                     : $MEDefaultBg !default;
//$MENewsButtonSize                   : 14px !default;
//$MENewsButtonFg                     : white !default;
//$MENewsButtonRadius                 : 3px !default;
//$MENewsBorder                       : 5px solid $MEDefaultBg !default;
//$MENewsMenuBorder                   : 1px dotted black !default;
//$MENewsMenuPad                      : 10px 0 !default;
//$MENewsMenuColor                    : #333333 !default;
//$MENewsMenuHoverColor               : #333333 !default;
//$MENewsHeadingColor                 : #333333 !default;
//$MENewsMenuBg                       : transparent !default;
//$MENewsMenuBgHover                  : transparent !default;
//
//// Product Settings
//// ================
//$MEProdItemMinHeight                : 1px !default;
//$MEProdWrapBorder                   : 1px solid #cccccc !default;
//$MEProdWrapPad                      : 20px !default;
//$MEProdHeadingSize                  : 18px !default;
//$MEProdHeadingFamily                : $MEDefaultFontFamily !default;
//$MEProdSubHeadingSize               : 16px !default;
//$MEProdSubHeadingFamily             : $MEDefaultFontFamily !default;
//$MEProdSummaryFontSize              : 14px !default;
//$MEProdSummaryLineHeight            : 1.5 !default;
